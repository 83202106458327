import {Component, Vue} from "vue-property-decorator";
import gql from "graphql-tag";
import {ApolloQueryResult} from "apollo-client";
import {ListMessages, ListMessages_ListMessages} from "@/generated/ListMessages";
import { RenewMessage } from '@/generated/RenewMessage'
import { GraphqlCreator } from '@/generated/GraphqlCreator'
import { Pagination } from '@/generated/globalTypes'

@Component
export default class MessageService extends Vue {

  private listMessages = gql`
    query ListMessages($pagination: Pagination!) {
      ListMessages(a0: $pagination) {
            items {
                id
                created
                message
                type
                planning
                shipment
                consignment               
                user {
                  email
                }
            }
        total
      }
    }
  `

  private renewMessages = gql`
    mutation RenewMessage($id: Int!) {
      RenewMessage(a0: $id)
    }
  `

  renewMessage(id: number): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.renewMessages,
          variables: {
            id: id
          }
        })
        .then((result: ApolloQueryResult<RenewMessage>) => {
          resolve(result.data.RenewMessage)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  getMessagesOverview(id: number | null = null, pagination: Pagination): Promise<ListMessages_ListMessages> {
    return new Promise((resolve, reject) => {
      this.$apollo
          .query({
            query: this.listMessages,
            variables: {
              pagination: pagination
            }
          })
          .then((result: ApolloQueryResult<ListMessages>) => {
            resolve(result.data.ListMessages)
          })
          .catch(reason => {
            reject(reason)
          })
    })
  }
}
