





























import {Component, Vue} from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import BaseDataTable from "@/general/components/general/BaseDataTable.vue";

@Component({
  components: {
    BaseDataTable,
    InfoCard
  }
})
export default class StatusRequirementsComponent extends Vue {
  // @Prop({required: true}) value: StatusRequirementsDTO
  //
  // statusRequirementsEnum = StatusRequirementsEnum
  // statusRequirementLevelEnum = StatusRequirementLevelEnum
  //
  // shouldShow(pickup: boolean, statusRequirement: StatusRequirementsEnum) {
  //   if (pickup && statusRequirement === StatusRequirementsEnum.signature) {
  //     return false
  //   }
  //   return true
  // }
  // get headers() {
  //   return [new Header('Requirement', 'requirement', false), new Header('Pickup', 'required', false), new Header('Delivery', 'optional', false)]
  // }
}
