






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AddButton extends Vue {
  @Prop({ required: true }) view!: string
}
