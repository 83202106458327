










































import DownloadMixin from "@/general/mixins/DownloadMixin";

export class DocumentLocation {
  location: String
  document: GetConsignment_GetConsignment_documents

  constructor(document: GetConsignment_GetConsignment_documents, location: string = '') {
    this.location = location
    this.document = document
  }
}

import {Component, Mixins, Prop, Vue} from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { Header } from '@/general/models/Vuetify'
import DocumentTools from '@/modules/shipments/models/Document'
import {GetConsignment_GetConsignment, GetConsignment_GetConsignment_documents} from "@/generated/GetConsignment";

@Component({
  components: {
    InfoCard
  }
})
export default class General extends Mixins(DownloadMixin) {
  @Prop({default: () => []}) documentLocations: DocumentLocation[]
  @Prop({default: () => []}) documents: GetConsignment_GetConsignment_documents[]
  @Prop() id: string
  @Prop({ default: false }) hideHeaders: boolean

  get getDocuments(): DocumentLocation[] {
    return this.documentLocations.concat(this.documents.map(doc => new DocumentLocation(doc, '')))
  }

  get headers(): Header[] {
    return (this.documentLocations.length ? [new Header('shipment.consignment.single', 'location')] : []).concat(
        [new Header('shipment.documents.fields.file', 'document.name'), new Header('common.type', 'document.type'), new Header('common.actions', 'actions', false)]
    )
  }

  getImg = (image: GetConsignment_GetConsignment_documents) => {
    return `data:${image.mime};base64,${image.base64}`
  }

  downloadDocument(document: GetConsignment_GetConsignment_documents) {
    this.downloadFile(document)
  }

  downloadAll() {
    if (this.documents.length === 1) {
      this.downloadFile(this.documents[0])
    } else {
      const zip = new JSZip()
      const documents = zip.folder(this.id)

      this.documents.forEach((document: GetConsignment_GetConsignment_documents) => {
        const fileItem: File = DocumentTools.b64toFile(document)
        documents!.file(document.name, fileItem)
      })
      zip.generateAsync({ type: 'blob' }).then((value: Blob) => {
        saveAs(value, this.id)
      })
    }
  }
}
