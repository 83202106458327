

















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { Header } from '@/general/models/Vuetify'
import {
  GetShipments_GetShipment_consignments_actors_pickup_status,
} from "@/generated/GetShipments";
import {LIcon, LMap, LMarker, LTileLayer, LTooltip} from "vue2-leaflet";

@Component({
  components: {
    InfoCard,
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer
  }
})
export default class StatusMap extends Vue {
  @Prop({default: () => []}) statuses: GetShipments_GetShipment_consignments_actors_pickup_status[]
  center: string[] = ['52.373882', '4.891711']

  created() {
    if (this.statuses.length) {
      this.center = this.statuses[this.statuses.length - 1].location!.split(',')
    }
  }
  resize() {
    // Do this so the map loads properly
    // @ts-ignore
    this.$refs.map.mapObject._onResize()
  }

  @Watch('statuses')
  setCenter() {
    if (this.statuses.length) {
      this.center = this.statuses[this.statuses.length - 1].location!.split(',')
    } else {
      this.center = ['52.373882', '4.891711']
    }
  }

  toHex(s: string) {
    let i
    let hash = 0
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
      hash = s.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    let color = '#'
    for (i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 255
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  get headers(): Header[] {
    return [new Header('common.type', 'type'), new Header('common.amount', 'amount')]
  }
}
