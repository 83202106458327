import { saveAs } from 'file-saver'
import {DocumentCreateModel} from "@/generated/globalTypes";
import {GetConsignment_GetConsignment_documents} from "@/generated/GetConsignment";

class DocumentTools {
  static fromFile(value: File, document: DocumentCreateModel) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(value)
      document.name = value.name
      document.mime = value.type

      reader.onload = () => {
        document.base64 = (reader.result as string).split(',')[1]
        resolve()
      }
    })
  }

  static fromString(value: string, document: DocumentCreateModel) {
    const parts = value.split(',')
    document.mime = parts[0].replace('data:', '').replace(';base64', '')
    document.base64 = parts[1]
  }

  static save(document: GetConsignment_GetConsignment_documents) {
    saveAs(this.b64toFile(document))
  }

  static b64toFile(document: GetConsignment_GetConsignment_documents) {
    var sliceSize = 512;
    var byteCharacters = atob(document.base64);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);

      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new File(byteArrays, document.name, {type: document.mime});
  }
}

export default DocumentTools
