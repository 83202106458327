















































import {Component, Mixins, Prop} from 'vue-property-decorator'
import AddButton from '@/general/components/common/AddButton.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import AccessCodePopup from '@/general/components/common/AccessCodePopup.vue'
import Tabs from '@/general/components/common/Tabs.vue'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import BaseDateTimePicker from '@/general/components/general/BaseDateTimePicker.vue'
import BaseTextField from '@/general/components/general/BaseTextField.vue'
import BaseForm from '@/general/components/general/BaseForm.vue'
import Popup from '@/general/components/common/Popup.vue'
import FormMixin from '@/general/mixins/FormMixin'
import {ListAccessCodes_ListAccessCodes_items} from "@/generated/ListAccessCodes";
import {Header} from "@/general/models/Vuetify";
import {CreateAccessCodePlanningModel} from "@/generated/globalTypes";

@Component({
  components: {
    Popup,
    BaseForm,
    BaseTextField,
    BaseDateTimePicker,
    BaseDataTable,
    Tabs,
    AccessCodePopup,
    InfoCard,
    AddButton
  }
})
export default class AccessCodeOverview extends Mixins(FormMixin) {
  @Prop() id: number
  @Prop() identifier: string
  @Prop() getFunction: (id: number, items: number, page: number) => Promise<any>
  @Prop() createFunction: (createAccessCode: CreateAccessCodePlanningModel) => Promise<any>
  @Prop() getAccessCodeFunction: (accessCodeSecret: string) => string
  // @Prop() updateFunction: (id: string, secret: String, accessCodeDTO: CreateAccessCodeDTO) => Promise<any>
  // @Prop() shareFunction: (id: string, accessCode: String, sendAccessCodeDTO: SendAccessCodeDTO) => Promise<any>
  // @Prop() deleteFunction: (id: string, accessCode: String) => Promise<any>

  clickedAccessCode: string | null = null
  items: ListAccessCodes_ListAccessCodes_items[] = []

  createOrEditAccessCode: string | null = null
  createAccessCode: CreateAccessCodePlanningModel = {
    entity: -1,
    description: null,
    validTill: null
  }
  // deleteAccessCode: AccessCodeDTO | null = null

  get headers() {
    return [
      new Header('shipment.fields.createdAt', 'date'),
      new Header('scan.description', 'description'),
      new Header('Valid till', 'validTill'),
      new Header('common.actions', '')
    ]
  }

  created() {
    this.createAccessCode.entity = this.id
  }

  // startEdit(accessCodeDTO: AccessCodeDTO) {
  //   this.createAccessCode = new CreateAccessCodeDTO(accessCodeDTO.description, accessCodeDTO.validTill)
  //   this.createOrEditAccessCode = accessCodeDTO.secret
  // }
  //
  create() {
    if (this.createOrEditAccessCode === "create") {
      this.createFunction(this.createAccessCode).then(response => {
        this.items.splice(0, 0, response)
        ;(this.$refs.form as any).validator().reset()
        this.createAccessCode = {
          entity: this.id,
          description: null,
          validTill: null
        }
        this.createOrEditAccessCode = null
        this.$store.dispatch('snackbar/saved')
      })
    // } else {
    //   this.updateFunction(this.id, this.createOrEditAccessCode!!, this.createAccessCode).then(response => {
    //     this.items.splice(this.items.map(value => value.secret).indexOf(this.createOrEditAccessCode!!), 1, response.data)
    //     ;(this.$refs.form as any).validator().reset()
    //     this.createAccessCode = new CreateAccessCodeDTO(null, null)
    //     this.createOrEditAccessCode = null
    //     this.$store.dispatch('snackbar/saved')
    //   })
    // }
  }

  // service(search: string, pagination: BackendPagination, args: any) {
  //   return this.getFunction(this.id, pagination, args)
  // }
  //
  // remove() {
  //   let secret = this.deleteAccessCode!!.secret
  //   this.deleteFunction(this.id, this.deleteAccessCode!!.secret).then(result => {
  //     this.$store.dispatch('snackbar/removed')
  //     this.items.splice(this.items.map(value => value.secret).indexOf(secret), 1)
  //     this.deleteAccessCode = null
  //   })
  // }
  //
  // sendAccess(event: any) {
    // this.shareFunction(this.id, this.clickedAccessCode!!, event).then(response => {
    //   this.clickedAccessCode = null
    //   this.$store.dispatch('snackbar/sent')
    // })
  }
}
