




import { Component, Prop, Vue } from 'vue-property-decorator'
import sanitizeHTML from 'sanitize-html'
import QRCode from 'qrcode-generator'

@Component
export default class QrCode extends Vue {
  @Prop() consignmentId: string
  qrCode: any
  environement = process.env.NODE_ENV

  created() {
    const qrcodeFactory = QRCode(0, 'L')
    qrcodeFactory.addData(
      JSON.stringify(this.consignmentId)
    )
    qrcodeFactory.make()
    this.qrCode = sanitizeHTML(qrcodeFactory.createSvgTag(6, 0), {
      allowedTags: sanitizeHTML.defaults.allowedTags.concat(['svg', 'rect', 'path']),
      allowedAttributes: {
        path: ['d'],
        svg: ['width', 'height']
      }
    })
  }
}
