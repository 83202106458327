











import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { Header } from '@/general/models/Vuetify'
import {GetShipments_GetShipment_consignments_actors_pickup_status_pickedUpEmballage} from "@/generated/GetShipments";

@Component({
  components: {
    InfoCard
  }
})
export default class Emballage extends Vue {
  @Prop({default: () => []}) emballage: GetShipments_GetShipment_consignments_actors_pickup_status_pickedUpEmballage[]

  get headers(): Header[] {
    return [new Header('common.type', 'type'), new Header('common.amount', 'amount')]
  }
}
