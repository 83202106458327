



















import { Component, Prop, Vue } from 'vue-property-decorator'

class Item {
  icon: string
  text: string
  translate: boolean
}

@Component
export default class OptionMenuButton extends Vue {
  @Prop({ required: false, default: 'more_vert' }) icon: string
  @Prop({ required: false, default: () => [] }) icons: string[]
  @Prop({ required: false, default: () => [] }) items: Item[]

  sendEvent(pressed: string) {
    this.$emit('click', pressed)
  }
}
