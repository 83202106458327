import { Component, Provide, Vue } from 'vue-property-decorator'
import { AxiosError } from 'axios'

@Component
export default class FormMixin extends Vue {
  @Provide() errorMessage: string = ''

  @Provide()
  get objectValidator() {
    return this.validator()
  }

  validator(): any {
    console.log('Validator not implemented')
    return {}
  }

  catchError(error: AxiosError) {
    if (error.response && 'objectName' in error.response.data && 'message' in error.response.data) {
      const object = error.response.data.objectName
      const field = error.response.data.field
      const message = this.$t('errors.api.' + error.response.data.status, { field: field.charAt(0).toUpperCase() + field.slice(1) }).toString()

      if ('field' in error.response.data) {
        ;(this.$refs.form as any).validator().errors[error.response.data.field].push(message)
      } else {
        this.errorMessage = object + ': ' + message
      }

      this.$store.commit('snackbar/show', {
        color: 'error',
        text: message
      })
    }
  }

  submitForm() {
    ;(this.$refs.form as any)
      .validator()
      .validate()
      .then((result: any) => {
        if (result) {
          try {
            this.submit()
          } catch (e) {
            ;(this.$refs.form as any).validator().errors[e.message].push(e.message.charAt(0).toUpperCase() + e.message.slice(1) + ': Invalid value')
          }
        }
      })
  }

  submit() {
    console.log('implement a submit function in the form validator component')
  }
}
