import gql from 'graphql-tag'

export const DOCUMENT_EXPORT_MODEL_FRAGMENT = gql`
  fragment DocumentExportModelFields on DocumentExportModel {
    base64
    meta
    mime
    name
    type
  }
`
