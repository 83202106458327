

































































import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import {GetShipments_GetShipment_consignments} from "@/generated/GetShipments";

@Component({
  components: {
    InfoCard
  }
})
export default class ShipmentBase extends Vue {
  @Prop({ required: true }) value: GetShipments_GetShipment_consignments
}
