









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Tabs extends Vue {
  @Prop() tabs: {
    name: string
    class: string | undefined
  }[]
  @Prop({ default: 0 }) value: number
}
