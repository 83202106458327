import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { ApolloQueryResult } from 'apollo-client'
import { GetECMRSByShipment, GetECMRSByShipment_GetECMRSByShipment } from '@/generated/GetECMRSByShipment'
import { DownloadECMR, DownloadECMR_DownloadECMR } from '@/generated/DownloadECMR'
import { Pagination } from '@/generated/globalTypes'
import { ListECMRS, ListECMRS_ListECMRS } from '@/generated/ListECMRS'
import { GetECMR, GetECMR_GetECMR } from '@/generated/GetECMR'
import { GetECMRSByPlanning, GetECMRSByPlanning_GetECMRSByPlanning_items } from '@/generated/GetECMRSByPlanning'
import {EmailECMR} from "@/generated/EmailECMR";
import {GetECMRSByPlanningAccessCode} from "@/generated/GetECMRSByPlanningAccessCode";
import {EmailECMRAccessCode} from "@/generated/EmailECMRAccessCode";
import {DOCUMENT_MODEL_FRAGMENT} from "@/modules/graphql/DocumentModelFragment";
import {DOCUMENT_EXPORT_MODEL_FRAGMENT} from "@/modules/graphql/DocumentExportModelFragment";
import {DownloadECMRAccessCode} from "@/generated/DownloadECMRAccessCode";
import { GetECMRReport, GetECMRReport_GetECMRReport } from '@/generated/GetECMRReport'

@Component
export default class ECMRService extends Vue {

    private ECMR_ITEM_FRAGMENT = gql`
        fragment ECMRItemFields on ECMRListItem {
            id
            identifier
            created
            sender
            transporter
            receiver
            shipment
            consignment
            status
        }
`

    private ECMR_FRAGMENT = gql`
        ${this.ECMR_ITEM_FRAGMENT}
        fragment ECMRFields on ECMRData {
            listData {
                ...ECMRItemFields
            }
            actorId
            identifier
            transporterCode
            field1
            field2
            field3
            field4
            field5
            field6
            goods {
                field7And8
                field9
                field10
                field11
            }
            field13
            field14
            field16a
            field16b
            field18
            field21a
            field21b
            field22 {
                signature
                person {
                    name
                    message
                }
                date
                company
            }
            field23
            field24 {
                signature
                person {
                    name
                    message
                }
                date
                company
            }
            incoterms
            
            deliveryPickedUpEmballage {
                items {
                    key
                    value
                }
            }
            
            statusDimensions {
                created
                attributeId
                unitCode
                value
            }
        }
`

    private emailECMRQuery = gql`
        mutation EmailECMR($id: Int!, $email: Email!) {
            EmailECMR(a0: $id, a1: $email)
        }
    `

    private emailECMRQueryAccessCode = gql`
        mutation EmailECMRAccessCode($id: Int!, $email: Email!) {
            EmailECMRAccessCode(a0: $id, a1: $email)
        }
    `

    private ecmrReportQuery = gql`
        query GetECMRReport($pagination: Pagination!) {
            GetECMRReport(a0: $pagination) {
                identifier
                company
                created
            }
        }
    `

    getECMRReport(pagination: Pagination): Promise<GetECMRReport_GetECMRReport[]> {
        return new Promise((resolve, reject) => {
            this.$apollo
              .query({
                query: this.ecmrReportQuery,
                variables: {
                  pagination: pagination
                }
              })
              .then((result: ApolloQueryResult<GetECMRReport>) => {
                resolve(result.data.GetECMRReport)
              })
              .catch(reason => {
                reject(reason)
              })
          })
    }

    getECMR(id: number): Promise<GetECMR_GetECMR> {
        return new Promise((resolve, reject) => {
            this.$apollo
              .query({
                query: this.getECMRQuery,
                variables: {
                  id: id
                }
              })
              .then((result: ApolloQueryResult<GetECMR>) => {
                resolve(result.data.GetECMR)
              })
              .catch(reason => {
                reject(reason)
              })
          })
    }

    private getECMRQuery = gql`
        ${this.ECMR_FRAGMENT}

        query GetECMR($id: Int!) {
            GetECMR(a0: $id) {
                ...ECMRFields
            }
        }
    `

    getECMROverview(entityID: number | null = null, pagination: Pagination): Promise<ListECMRS_ListECMRS> {
        return new Promise((resolve, reject) => {
          this.$apollo
            .query({
              query: this.listECMRS,
              variables: {
                pagination: pagination
              }
            })
            .then((result: ApolloQueryResult<ListECMRS>) => {
              resolve(result.data.ListECMRS)
            })
            .catch(reason => {
              reject(reason)
            })
        })
    }

    private listECMRS = gql`
        ${this.ECMR_ITEM_FRAGMENT}

        query ListECMRS($pagination: Pagination!) {
            ListECMRS(a0: $pagination) {
                items {
                    ...ECMRItemFields
                }
                total
            }
        }
    `

    getECMRSByShipment(id: number): Promise<GetECMRSByShipment_GetECMRSByShipment[]> {
        return new Promise((resolve, reject) => {
        this.$apollo
            .query({
                query: this.getECMRSByShipmentQuery,
                variables: {
                    id: id
                }
            })
            .then((result: ApolloQueryResult<GetECMRSByShipment>) => {
                resolve(result.data.GetECMRSByShipment)
            })
            .catch(reason => {
                reject(reason)
            })
        })
    }

    private getECMRSByShipmentQuery = gql`
        ${this.ECMR_FRAGMENT}

        query GetECMRSByShipment($id: Int!) {
            GetECMRSByShipment(a0: $id) {
                ...ECMRFields
            }
        }
    `

    getECMRSByPlanning(id: number): Promise<GetECMRSByPlanning_GetECMRSByPlanning_items[]> {
        return new Promise((resolve, reject) => {
        this.$apollo
            .query({
                query: this.$route.query.accessCode ? this.getECMRSByPlanningAccessCodeQuery :this.getECMRSByPlanningQuery,
                variables: {
                    id: id
                }
            })
            .then((result: ApolloQueryResult<GetECMRSByPlanning | GetECMRSByPlanningAccessCode>) => {
                //@ts-ignore
                resolve(this.$route.query.accessCode ? result.data.GetECMRSByPlanningAccessCode.items : result.data.GetECMRSByPlanning.items)
            })
            .catch(reason => {
                reject(reason)
            })
        })
    }

    private getECMRSByPlanningQuery = gql`
        ${this.ECMR_FRAGMENT}

        query GetECMRSByPlanning($id: Int!) {
            GetECMRSByPlanning(a0: $id) {
                items {
                    key
                    value {
                        ...ECMRFields
                    }
                }
            }
        }
    `

    private getECMRSByPlanningAccessCodeQuery = gql`
        ${this.ECMR_FRAGMENT}

        query GetECMRSByPlanningAccessCode {
            GetECMRSByPlanningAccessCode {
                items {
                    key
                    value {
                        ...ECMRFields
                    }
                }
            }
        }
    `

    downloadECMR(id: number): Promise<DownloadECMR_DownloadECMR> {
        return new Promise((resolve, reject) => {
        this.$apollo
            .query({
                query: this.$route.query.accessCode ? this.downloadECMRQueryAccessCode : this.downloadECMRQuery,
                variables: {
                    id: id
                }
            })
            .then((result: ApolloQueryResult<DownloadECMR | DownloadECMRAccessCode>) => {
                //@ts-ignore
                resolve(this.$route.query.accessCode ? result.data.DownloadECMRAccessCode : result.data.DownloadECMR)
            })
            .catch(reason => {
                reject(reason)
            })
        })
    }

    emailECMR(id: number, email: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.$apollo
                .query({
                    query: this.$route.query.accessCode ? this.emailECMRQueryAccessCode : this.emailECMRQuery,
                    variables: {
                        id: id,
                        email: email
                    }
                })
                .then((result: ApolloQueryResult<EmailECMR | EmailECMRAccessCode>) => {
                    //@ts-ignore
                    resolve(this.$route.query.accessCode ? result.data.EmailECMRAccessCode : result.data.EmailECMR)
                })
                .catch(reason => {
                    reject(reason)
                })
        })
    }

    private downloadECMRQuery = gql`
        ${DOCUMENT_EXPORT_MODEL_FRAGMENT}
        query DownloadECMR($id: Int!) {
            DownloadECMR(a0: $id) {
                ...DocumentExportModelFields
            }
        }
    `

    private downloadECMRQueryAccessCode = gql`
        ${DOCUMENT_EXPORT_MODEL_FRAGMENT}
        query DownloadECMRAccessCode($id: Int!) {
            DownloadECMRAccessCode(a0: $id) {
                ...DocumentExportModelFields
            }
        }
    `
}