import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { ApolloQueryResult } from 'apollo-client'
import { ListAccessCodes, ListAccessCodes_ListAccessCodes } from '@/generated/ListAccessCodes'
import {
  AccessCodeNotificationCreate,
  CreateAccessCodePlanningModel,
  CreateUserModel,
  Pagination
} from '@/generated/globalTypes'
import { CreateUser_CreateUser } from '@/generated/CreateUser'
import { CreateAccessCode, CreateAccessCode_CreateAccessCode } from '@/generated/CreateAccessCode'
import { CreateAccessCodeNotification } from '@/generated/CreateAccessCodeNotification'
import { FetchResult } from 'apollo-link'

@Component
export default class PlanningAccessCodeService extends Vue {
  private listAccessCodes = gql`
    query ListAccessCodes($pagination: Pagination!, $entityID: Int!, ) {
      ListAccessCodes(a0: $pagination, a1: $entityID) {
        items {
          secret
          description
          validTill
          created
        }
        total
      }
    }
  `

  private accessCodeNotificationCreate = gql`
    mutation CreateAccessCodeNotification($accessCode: UUID!, $accessCodeNotificationCreate: AccessCodeNotificationCreate!) {
      CreateAccessCodeNotification(a0: $accessCode, a1: $accessCodeNotificationCreate)
    }
  `

  private createAccessCode = gql`
    mutation CreateAccessCode($accessCodeCreate: CreateAccessCodePlanningModel!) {
      CreateAccessCode(a0: $accessCodeCreate) {
        secret
      }
    }
  `

  getPlanningAccessCodeOverview(entityID: number, pagination: Pagination): Promise<ListAccessCodes_ListAccessCodes> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.listAccessCodes,
          variables: {
            entityID: entityID,
            pagination: pagination
          }
        })
        .then((result: ApolloQueryResult<ListAccessCodes>) => {
          resolve(result.data.ListAccessCodes)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  createPlanningAccessCode(createAccessCode: CreateAccessCodePlanningModel): Promise<CreateAccessCode_CreateAccessCode> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .mutate({
          mutation: this.createAccessCode,
          variables: {
            accessCodeCreate: createAccessCode
          }
        })
        .then(result => {
          resolve(result.data.accessCodeCreate)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  createPlanningAccessCodeNotification(accessCodeSecret: string, createAccessCodeNotification: AccessCodeNotificationCreate): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .mutate({
          mutation: this.accessCodeNotificationCreate,
          variables: {
            accessCode: accessCodeSecret,
            accessCodeNotificationCreate: createAccessCodeNotification
          }
        })
        .then((result: FetchResult<CreateAccessCodeNotification>) => {
          resolve(result.data?.CreateAccessCodeNotification)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
