

































import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { GetShipments_GetShipment_consignments_actors_pickup_status } from '@/generated/GetShipments'
import Documents from '@/modules/shipments/views/detail/components/Documents.vue'
import Emballage from '@/modules/shipments/views/detail/components/Emballage.vue'

@Component({
  components: {
    Emballage,
    Documents,
    InfoCard
  }
})
export default class StatusInfo extends Vue {
  @Prop({ required: true }) title: string
  @Prop({ required: true }) status: GetShipments_GetShipment_consignments_actors_pickup_status

  get hasEmballage(): boolean {
    return (this.status.deliveredEmballage?.items?.length || this.status.pickedUpEmballage?.items?.length) !== undefined
  }
}
