








import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseTextField from '@/general/components/general/BaseTextField.vue'
import BaseTextArea from '@/general/components/general/BaseTextArea.vue'
import BaseForm from '@/general/components/general/BaseForm.vue'
import Popup from '@/general/components/common/Popup.vue'
import BaseSwitch from '@/general/components/general/BaseSwitch.vue'
import BaseCheckBox from '@/general/components/general/BaseCheckBox.vue'
@Component({
  components: { BaseCheckBox, BaseSwitch, BaseForm, Popup, BaseTextArea, BaseTextField }
})
export default class ResyncPopup extends Vue {
  // @Prop( { required: true }) value: boolean
  // @Prop({ required: false }) company: string
  // @Prop({ required: false }) shipment: string
  //
  // args = {
  //   erp: true,
  //   missing: true
  // }
  //
  // submit() {
  //   if (this.shipment) {
  //     resyncShipment(this.args.erp, this.shipment)
  //   } else if (this.company) {
  //     resyncShipments(this.args.missing, this.args.erp, this.company)
  //   } else {
  //     resyncShipmentsAll(this.args.missing, this.args.erp)
  //   }
  //   this.$store.commit('snackbar/show', {
  //     color: 'success',
  //     text: 'Resyncing shipments'
  //   })
  //   this.$emit('close')
  // }
}
