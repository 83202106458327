




























import { Component, Mixins } from 'vue-property-decorator'
import Tabs from '@/general/components/common/Tabs.vue'
import VerticalTabs from '@/modules/shipments/components/VerticalTabs.vue'
import DetailConsignment from '@/modules/shipments/views/detail/components/Detail.vue'
import General from '@/modules/shipments/views/detail/components/General.vue'
import ResyncPopup from '@/general/components/common/ResyncPopup.vue'
import ShipmentService from "@/modules/shipments/services/ShipmentService";
import {GetShipments_GetShipment, GetShipments_GetShipment_consignments} from "@/generated/GetShipments";
import { GetECMRSByShipment_GetECMRSByShipment } from "@/generated/GetECMRSByShipment";
import ECMRService from '@/modules/ecmrs/ECMRService'

@Component({
  components: {
    ResyncPopup,
    Tabs,
    DetailConsignment,
    General,
    VerticalTabs
  }
})
export default class Detail extends Mixins(ShipmentService, ECMRService) {
  tab = 0
  shipment: GetShipments_GetShipment | null = null
  ecmrs: GetECMRSByShipment_GetECMRSByShipment[] = []
  admin: boolean = this.$utils.hasRoles(['ROLE_ADMIN'])
  resync = false
  listener: EventListener

  get tabs() {
    if (this.shipment) {
      const tabs = []
      if (this.shipment.consignments.length > 1) {
        tabs.push({ name: this.$t('common.general').toString() })
      }
      this.shipment.consignments.forEach((consignment: GetShipments_GetShipment_consignments, index: number) =>
        tabs.push({ name: `${this.$t('shipment.consignment.single')} ${index + 1}` })
      )
      return tabs
    }
    return []
  }

  created() {
    this.$websocket.eventEmitter.on('SHIPMENT', this.fetchShipment)
    this.fetchShipment(Number(this.$route.params.id))
  }

  destroyed() {
    this.$websocket.eventEmitter.removeListener('SHIPMENT', this.fetchShipment)
  }

  private getActions() {
    // return this.shipment?.consignments?.map(consignment => {
    //   return consignment.status[consignment.actors.findIndex((actor: Actor) => actor.erpId === this.shipment?.actorId)] !== null ? 'UNLOAD' : 'LOAD'
    // })
  }

  fetchShipment(id: number) {
    if (id === Number(this.$route.params.id)) {
      this.getShipment(id).then(shipment => {
        this.shipment = shipment
        this.getECMRSByShipment(id).then(ecmrs => {
          this.ecmrs = ecmrs
        })
        // this.shipment!.consignments.forEach(consignment => {
        //   consignment.actors.forEach((actor: Actor, index: number) => {
        //     ;(actor as HolderActor).status = consignment.status[index] ?? null
        //   })
        // })
      })
    }
  }
}
