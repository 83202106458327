export const emailValidator = {
  required: true,
  email: true
}

export const passwordValidator = {
  required: true,
  regex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$'
}

export const validator = {
  email: emailValidator,
  password: passwordValidator,
  changePassword: passwordValidator
}

export class UserRegister {
  email = ''
  password = ''
}

export class UserOverview {
  email = ''
}

interface StoredCompany {
  id: string
  name: string
  activated: boolean
}

export interface StoredUser {
  sub: string
  email: string
  authorities: string[]
  company: StoredCompany
  loggedIn: boolean
}
