import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { ApolloQueryResult } from 'apollo-client'
import {
    ListExciseStatusesForConsignment,
    ListExciseStatusesForConsignment_ListExciseStatusesForConsignment
} from "@/generated/ListExciseStatusesForConsignment";

@Component
export default class ExciseService extends Vue {
  private listExciseStatusesForConsignmentQuery = gql`
		query ListExciseStatusesForConsignment($id: Int!) {
			ListExciseStatusesForConsignment(a0: $id) {
			  created
			  status
			  blockchainHash
			}
		}`

  listExciseStatusesForConsignment(id: number): Promise<ListExciseStatusesForConsignment_ListExciseStatusesForConsignment[]> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.listExciseStatusesForConsignmentQuery,
          variables: {
            id: id
          }
        })
        .then((result: ApolloQueryResult<ListExciseStatusesForConsignment>) => {
          resolve(result.data.ListExciseStatusesForConsignment)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }
}
