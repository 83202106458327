



































































import DownloadMixin from "@/general/mixins/DownloadMixin";
import {Component, Mixins, Prop } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { Header } from '@/general/models/Vuetify'
import { GetECMRSByShipment_GetECMRSByShipment } from "@/generated/GetECMRSByShipment";
import ShipmentService from "@/modules/shipments/services/ShipmentService";
import ECMRService from "@/modules/ecmrs/ECMRService";
import AuthenticationService from "@/general/services/AuthenticationService";
import BaseDataTable from "@/general/components/general/BaseDataTable.vue";
import Popup from "@/general/components/common/Popup.vue";
import FormMixin from "@/general/mixins/FormMixin";
import BaseTextField from "@/general/components/general/BaseTextField.vue";
import BaseForm from "@/general/components/general/BaseForm.vue";

@Component({
  components: {
    BaseForm,
    BaseTextField,
    Popup,
    BaseDataTable,
    InfoCard
  }
})
export default class ECMR extends Mixins(DownloadMixin, ShipmentService, ECMRService, AuthenticationService, FormMixin) {
  @Prop({default: () => []}) ecmrs: GetECMRSByShipment_GetECMRSByShipment[]

  clickedECMRToShare: GetECMRSByShipment_GetECMRSByShipment | null = null
  email: string = ''

  validator(): any {
    return { email: {required: true, regex: '^.*@.*$'} }
  }

  get headers() {
    return [
      new Header('ID', 'identifier'),
      new Header('shipment.fields.createdAt', 'created'),
      new Header('tradelane.fields.sender', 'sender'),
      new Header('common.actor', 'transporter'),
      new Header('tradelane.fields.receiver', 'receiver'),
      new Header('shipment.consignment.single', 'consignment'),
      new Header('shipment.single', 'shipment'),
      new Header('shipment.status', 'status'),
      new Header('common.actions', 'actions', false)
    ]
  }

  get mobileHeaders() {
    return [
      new Header('ID', 'identifier'),
      new Header('tradelane.fields.sender', 'sender'),
      new Header('tradelane.fields.receiver', 'receiver'),
      new Header('shipment.status', 'status'),
      new Header('common.actions', 'actions', false)
    ]
  }
  submit() {
    this.emailECMR(this.clickedECMRToShare?.listData?.id!!, this.email).then(result => {
      this.email = ''
      this.clickedECMRToShare = null
    })
    // send email
  }
  downloadDocument(id: number) {
    this.downloadECMR(id).then(ecmr => {
      this.saveECMR(ecmr)
    })
  }
}
