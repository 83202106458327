





























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Tabs from '@/general/components/common/Tabs.vue'
import ShipmentBase from '@/modules/shipments/views/detail/components/ShipmentBase.vue'
import ShipmentArticles from '@/modules/shipments/views/detail/components/ShipmentArticles.vue'
import ShipmentStatus from '@/modules/shipments/views/detail/components/ShipmentStatus.vue'
import QrCode from '@/general/components/qr/QrCode.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import ECMR from '@/modules/shipments/views/detail/components/ECMR.vue'
import CurrentStage from '@/modules/shipments/views/detail/components/CurrentStage.vue'
import Popup from '@/general/components/common/Popup.vue'
import { emailValidator } from '@/modules/users/models/User'
import AccessCodePopup from '@/general/components/common/AccessCodePopup.vue'
import AccessCodeOverview from '@/modules/planning/views/AccessCodeOverview.vue'
import Documents, {DocumentLocation} from "@/modules/shipments/views/detail/components/Documents.vue";
import ResyncPopup from "@/general/components/common/ResyncPopup.vue";
import { GetECMRSByShipment_GetECMRSByShipment } from "@/generated/GetECMRSByShipment";
import {
  GetShipments_GetShipment,
  GetShipments_GetShipment_consignments,
  GetShipments_GetShipment_consignments_actors,
  GetShipments_GetShipment_consignments_actors_pickup_status,
  GetShipments_GetShipment_consignments_documents
} from "@/generated/GetShipments";
import ExciseStatuses from "@/modules/excise/views/ExciseStatuses.vue";


@Component({
  components: {
    ExciseStatuses,
    ResyncPopup,
    AccessCodeOverview,
    AccessCodePopup,
    Popup,
    Tabs,
    ShipmentBase,
    ShipmentArticles,
    ShipmentStatus,
    QrCode,
    InfoCard,
    ECMR,
    CurrentStage,
    Documents
  }
})
export default class Detail extends Vue {
  @Prop() ecmrs: GetECMRSByShipment_GetECMRSByShipment[]
  @Prop() consignment: GetShipments_GetShipment_consignments
  @Prop() shipment: GetShipments_GetShipment

  accessCode = ''
  stages: any[] = [null, null, null]
  admin: boolean = this.$utils.hasRoles(['ROLE_ADMIN'])
  resync = false

  tab = 0
  step = 0

  validator(): any {
    return { email: emailValidator }
  }

  get isFinished() {
    // return this.consignment.status[this.consignment.status.length - 1]
    return false
  }

  created() {
    this.formatConsignment()
  }

  private showScanButtons(): GetShipments_GetShipment_consignments_actors | undefined {
    return this.consignment.actors.find((actor: GetShipments_GetShipment_consignments_actors) => actor.company.actorId === this.shipment.actorId)
  }

  private get documentLocations(): DocumentLocation[] {
    return this.shipment.consignments.flatMap(consignment => this.getActors(consignment).map((actor, index) => new KeyValue(consignment.name + ' | stage: ' + index, actor)).filter(kv => kv.value.pickup.status?.documents)).flatMap(kv => this.documentsPlusSignature(kv.value.pickup.status!!).map(doc => new DocumentLocation(doc, kv.key)))
  }

  private documentsPlusSignature(status: GetShipments_GetShipment_consignments_actors_pickup_status): GetShipments_GetShipment_consignments_documents[] {
    const docs: GetShipments_GetShipment_consignments_documents[] = []
    if (status.documents && status.documents.length) docs.push(...status.documents)
    // if (status.signature) docs.push(status.signature)
    return docs
  }

  private getActors(consignment: GetShipments_GetShipment_consignments): GetShipments_GetShipment_consignments_actors[] {
    return consignment.actors
  }

  private formatConsignment() {
    let currentStage = 0

    this.consignment.actors.forEach((actor, index) => {
      if (actor.delivery.status) currentStage = index + 1
    })

    const actorsAmount = this.consignment.actors.length

    this.step = currentStage + 1

    const actors: any[] = []

    if (actorsAmount + 1 === currentStage || actorsAmount === currentStage || actorsAmount === currentStage + 1) {
      actors.push(this.getStatusContact(actorsAmount - 3))
      actors.push(this.getStatusContact(actorsAmount - 2))
      actors.push(this.getStatusContact(actorsAmount - 1))
    } else if (currentStage === 0 || currentStage === 1) {
      actors.push(this.getStatusContact(0))
      actors.push(this.getStatusContact(1))
      actors.push(this.getStatusContact(2))
    } else {
      actors.push(this.getStatusContact(currentStage - 1))
      actors.push(this.getStatusContact(currentStage))
      actors.push(this.getStatusContact(currentStage + 1))
    }

    for (let j = 0; j < 3; j++) {
      this.$set(this.stages, j, null)
    }

    let i = 0

    actors.forEach(actor => {
      if (actor.actor) {
        this.$set(this.stages, i++, actor)
      }
    })
  }

  private getStatusContact(index: number) {
    return {
      actor: this.consignment.actors[index],
      stage: index + 1
    }
  }

  @Watch('consignment', { deep: true })
  private consignmentUpdate() {
    this.formatConsignment()
  }
}

class KeyValue {
  key: string
  value: GetShipments_GetShipment_consignments_actors
  constructor(key: string, value: GetShipments_GetShipment_consignments_actors) {
    this.key = key
    this.value = value
  }
}

