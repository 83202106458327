


































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Header } from '@/general/models/Vuetify'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { STATUS_TYPES } from '@/modules/shipments/models/Status'
import { saveAs } from 'file-saver'
import Overview from '@/modules/messages/views/Messages.vue'
import Documents from '@/modules/shipments/views/detail/components/Documents.vue'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import StatusRequirementsComponent from '@/modules/documents/StatusRequirementsComponent.vue'
import {
  GetShipments_GetShipment_consignments,
  GetShipments_GetShipment_consignments_actors,
  GetShipments_GetShipment_consignments_actors_pickup_status,
  GetShipments_GetShipment_consignments_documents
} from '@/generated/GetShipments'
import StatusInfo from '@/modules/shipments/views/detail/components/StatusInfo.vue'
import {LIcon, LMap, LMarker, LTileLayer, LTooltip} from "vue2-leaflet";
import StatusMap from "@/modules/shipments/views/detail/components/StatusMap.vue";

@Component({
  components: {
    StatusMap,
    StatusInfo,
    StatusRequirementsComponent,
    BaseDataTable,
    Documents,
    InfoCard,
    Overview,
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer
  }
})
export default class ShipmentStatus extends Vue {
  @Prop() consignment: GetShipments_GetShipment_consignments
  showStatusRequirements = false
  statusTypes = STATUS_TYPES

  get actors(): GetShipments_GetShipment_consignments_actors[] {
    let actors: GetShipments_GetShipment_consignments_actors[] = []

    // @ts-ignore
    const pickup: GetShipments_GetShipment_consignments_actors = {
      company: this.consignment.sender
    }
    // @ts-ignore
    const delivery: GetShipments_GetShipment_consignments_actors = {
      company: this.consignment.receiver
    }
    actors.push(pickup)
    actors.push(...this.consignment.actors)
    actors.push(delivery)
    return actors
  }

  get headers() {
    return [
      new Header('common.status', 'status'),
      new Header('common.contact', 'contact'),
      new Header('shipment.eta', 'estimatedTimeOfArrival'),
      new Header('shipment.ata', 'actualTimeOfArrival'),
      // new Header('common.location', 'location'),
      new Header('shipment.selectTradelane.fields.freightPrice', 'freightPrice')
      // new Header('common.attachments', 'documents'),
      // new Header('common.info', 'info'),
    ]
  }

  getStatuses(actor: GetShipments_GetShipment_consignments_actors): GetShipments_GetShipment_consignments_actors_pickup_status[] {
    return [actor.pickup.status, actor.delivery.status, ...(actor.progresses ? actor.progresses.map(status => status.status) : [])].filter(status => status).sort((a, b) => {
      if (a!!.id > b!!.id) {
        return 1
      } else {
        return -1
      }
    }) as GetShipments_GetShipment_consignments_actors_pickup_status[]
  }

  getImg = (image: GetShipments_GetShipment_consignments_documents) => {
    return `data:${image.mime};base64,${image.base64}`
  }

  downloadDocument(document: GetShipments_GetShipment_consignments_documents) {
    saveAs(`data:${document.mime};base64,${document.base64}`, document.name)
  }

  getIcon(actor: GetShipments_GetShipment_consignments_actors) {
    // If no status for delivery and pickup show empty icon
    if (!actor.pickup.status && !actor.delivery.status) return 'radio_button_unchecked'
    // If delivery accepted is true show stage as finished
    if (actor.delivery?.status) return 'check_circle'
    // If at least pickup or delivery is available show in progress
    if (!actor.pickup.status || !actor.delivery.status) return 'schedule'
  }

  private hasStatusInfo(actor: GetShipments_GetShipment_consignments_actors) {
    return actor.pickup?.status || actor.delivery?.status
  }

  getDocuments(status: GetShipments_GetShipment_consignments_actors_pickup_status | undefined) {
    if (status) {
      if (status.documents && status.documents.length) {
        return status.documents
      }
    }
    return []
  }
}
