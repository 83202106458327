import { Component, Vue } from 'vue-property-decorator'
import DocumentTools from "@/modules/shipments/models/Document";
import {GetConsignment_GetConsignment_documents} from "@/generated/GetConsignment";
import { DownloadECMRByActor_DownloadECMRByActor } from '@/generated/DownloadECMRByActor'

interface DownloadProvider {
  download(document: GetConsignment_GetConsignment_documents): void
}

class PWADownloadProvider implements DownloadProvider {
  download(document: GetConsignment_GetConsignment_documents): void {
    DocumentTools.save(document)
  }
}

@Component
class NativeAppDownloadProvider extends Vue implements DownloadProvider {
  download(document: GetConsignment_GetConsignment_documents): void {
    // @ts-ignore
    nativeAppDownloadService.download(document.name, document.mime, document.base64)
  }
}

@Component
export default class DownloadMixin extends Vue {
  private downloadProvider: DownloadProvider

  created() {
    if (this.$store.state.nativeMode) {
      this.downloadProvider = new NativeAppDownloadProvider()
    } else {
      this.downloadProvider = new PWADownloadProvider()
    }
  }

  saveECMR(document: DownloadECMRByActor_DownloadECMRByActor): void {
    return this.downloadProvider.download({ id: 0, ...document,  __typename: 'DocumentModel' })
  }

  downloadFile(document: GetConsignment_GetConsignment_documents): void {
    return this.downloadProvider.download(document)
  }
}
