





























import {Component, Prop, Mixins} from 'vue-property-decorator'
import MessageService from '@/modules/messages/MessageService'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { Header } from '@/general/models/Vuetify'
import SaveButton from '@/general/components/common/SaveButton.vue'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import Tabs from '@/general/components/common/Tabs.vue'
import {MessageType} from "@/generated/globalTypes";

@Component({
  components: {
    InfoCard,
    SaveButton,
    BaseDataTable,
    Tabs
  }
})
export default class Overview extends Mixins(MessageService) {
  @Prop({ required: false, default: null }) shipment: any
  args: any = null
  show = true
  tab = 0
  messageType = MessageType

  activity = []

  rerender() {
    this.show = false
    this.$nextTick(() => {
      this.show = true
    })
  }

  get headers() {
    return [
      new Header('common.date', 'date'),
      new Header('user.single', 'appUser'),
      new Header('common.status', 'status'),
      new Header('scan.fields.message', 'message'),
      new Header('nav.planning', 'planning'),
      new Header('shipment.single', 'shipment'),
      new Header('shipment.consignment.single', 'consignment'),
      new Header('', 'renew', false)
    ]
  }

  getStatus(status: MessageType): String {
    let returnStatus = ''
    switch (status) {
      case MessageType.SHIPMENT_CREATED:
        returnStatus = 'actions.shipmentCreated'
        break
      case MessageType.SHIPMENT_UPDATED:
        returnStatus = 'actions.shipmentUpdated'
        break
      case MessageType.PLANNING_CREATED:
        returnStatus = 'actions.planningCreated'
        break
      case MessageType.PLANNING_UPDATED:
        returnStatus = 'actions.planningUpdated'
        break
      case MessageType.ACCESS_CODE_CREATED:
        returnStatus = 'actions.accesscodeCreated'
        break
      case MessageType.ACCESS_CODE_SHARED:
        returnStatus = 'actions.accesscodeShared'
        break
      case MessageType.ECMR:
        returnStatus = 'e-CMR'
        break;
      case MessageType.NOTIFICATION_SENT:
        returnStatus = 'actions.notificationSent'
        break;
      case MessageType.PART_OF_ACCESS_CODE_CREATED:
        returnStatus = 'actions.partOfAccesscodeCreated'
        break;
      case MessageType.PART_OF_ACCESS_CODE_SHARED:
        returnStatus = 'actions.partOfAccesscodeShared'
        break;
      default:
        returnStatus = (status as MessageType).toString()
    }
    return returnStatus
  }
}
