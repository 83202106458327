






















import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import BaseTextField from '@/general/components/general/BaseTextField.vue'
import BaseTextArea from '@/general/components/general/BaseTextArea.vue'
import BaseForm from '@/general/components/general/BaseForm.vue'
import Popup from '@/general/components/common/Popup.vue'
import FormMixin from '@/general/mixins/FormMixin'
import {AccessCodeNotificationCreate, NotificationMedium} from "@/generated/globalTypes";
import PlanningAccessCodeService from "@/modules/planning/PlanningAccessCodeService";
@Component({
  components: { BaseForm, Popup, BaseTextArea, BaseTextField }
})
export default class AccessCodePopup extends Mixins(FormMixin, PlanningAccessCodeService) {
  @Prop() value: string
  @Prop() header: string
  @Prop() getAccessCodeFunction: (accessCodeSecret: string) => string

  smsOrEmail: string = 'email'
  companyName: string = window.location.origin.substring(window.location.origin.indexOf("://") + 3, this.getLastIndex())
  accessCode: AccessCodeNotificationCreate = { medium: this.smsOrEmail === "email" ? NotificationMedium.EMAIL : NotificationMedium.SMS, recipient: "", message: "" }

  submit() {
    this.accessCode.medium = (this.smsOrEmail === "email" ? NotificationMedium.EMAIL : NotificationMedium.SMS)
    this.createPlanningAccessCodeNotification(this.value, this.accessCode).then(result => {
        if (result) {
          this.$store.dispatch('snackbar/sent')
          this.$emit('closePopup')
        }
      }
    )
  }

  getLastIndex(): number {
    if (window.location.origin.lastIndexOf(".") === -1) {
      return window.location.origin.lastIndexOf(":")
    } else {
      return window.location.origin.lastIndexOf(".")
    }
  }

  validator(): any {
    // return this.$utils.validator(this.accessCode)
  }
  private copyAccessCode() {
    navigator.clipboard.writeText(this.getAccessCodeFunction(this.value))
    this.$store.dispatch('snackbar/copied')
  }
}
