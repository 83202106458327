
























import { Component, Prop, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import Documents from '@/modules/shipments/views/detail/components/Documents.vue'
import { Header } from '@/general/models/Vuetify'
import OptionMenuButton from '@/general/components/common/OptionMenuButton.vue'
import {
  GetShipments_GetShipment,
  GetShipments_GetShipment_consignments,
  GetShipments_GetShipment_consignments_actors
} from "@/generated/GetShipments";

@Component({
  components: {
    InfoCard,
    Documents,
    OptionMenuButton
  }
})
export default class General extends Vue {
  @Prop() shipment: GetShipments_GetShipment

  // get documentLocations(): DocumentLocation[] {
  //   // @ts-ignore
  //   return this.consignments.flatMap(consignment => this.getActors(consignment).map((actor, index) => new KeyValue(consignment.identifier + ' | stage: ' + index, actor)).filter(kv => kv.value.status)).flatMap(kv => this.documentsPlusSignature(kv.value.status).map(doc => new DocumentLocation(doc, kv.key)))
  // }
  //
  // documentsPlusSignature(status: Status): Document[] {
  //   const docs: Document[] = []
  //   if (status.documents && status.documents.length) docs.push(...status.documents)
  //   if (status.signature) docs.push(status.signature)
  //   return docs
  // }

  getActors(consignment: GetShipments_GetShipment_consignments): GetShipments_GetShipment_consignments_actors[] {
    // let actors: Actor[] = []
    // // @ts-ignore
    // const pickup: Actor = {
    //   contact: consignment.sender
    // }
    // actors.push(pickup)
    // actors.push(...consignment.actors)
    // actors.push(consignment.receiver!)
    return consignment.actors
  }

  get consignments(): GetShipments_GetShipment_consignments[] {
    // return this.shipment.consignments.map((consignment: any) => {
    //   const lastStatus = consignment.
    //
    //   consignment.currentActor = consignment.actors[Math.min(lastStatus, consignment.actors.length - 1)].dateOfArrival
    //   consignment.currentStatus = consignment.status[lastStatus] ? consignment.status[lastStatus].date : ''
    //
    //   consignment.class = 'early'
    //
    //   if (consignment.currentActor < consignment.currentStatus || new Date(consignment.currentActor) < new Date()) {
    //     consignment.class = 'late'
    //   }
    //
    //   return consignment
    // })
    return this.shipment.consignments
  }

  get headers() {
    return [
      new Header('shipment.consignment.single', 'stage'),
      new Header('scan.currentStage', 'stage'),
      new Header('shipment.eta', 'eta'),
      new Header('shipment.ata', 'ata')
    ]
  }
  //
  // getStage(status: StatusCreate[]): number {
  //   let lastStatus = 0
  //   status.forEach((value: any, index: number) => {
  //     if (value != null) lastStatus = index + 1
  //   })
  //   return lastStatus
  // }

  handleOptionButton(option: string) {
    switch (option) {
      case 'file_copy': {
        break
      }
    }
  }
}
