















import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import AddButton from '@/general/components/common/AddButton.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import {Header} from '@/general/models/Vuetify'
import Tabs from '@/general/components/common/Tabs.vue'
import PlanningService from "@/modules/planning/PlanningService";
import {ListPlannings_ListPlannings, ListPlannings_ListPlannings_items} from "@/generated/ListPlannings";
import {VehicleType} from "@/generated/globalTypes";
import ExciseService from "@/modules/excise/ExciseService";
import {GetConsignment_GetConsignment} from "@/generated/GetConsignment";
import {ListExciseStatusesForConsignment_ListExciseStatusesForConsignment} from "@/generated/ListExciseStatusesForConsignment";
import BaseDataTable from "@/general/components/general/BaseDataTable.vue";

@Component({
  components: {
    BaseDataTable,
    Tabs,
    InfoCard,
    AddButton
  }
})
export default class ExciseStatuses extends Mixins(ExciseService) {
  @Prop({ required: true }) consignment: GetConsignment_GetConsignment

  statuses: ListExciseStatusesForConsignment_ListExciseStatusesForConsignment[] = []

  get headers() {
    return [
      new Header('shipment.fields.createdAt', 'createdAt'),
      new Header('shipment.fields.updatedAt', 'updatedAt'),
    ]
  }


  created() {
    this.listExciseStatusesForConsignment(this.consignment.id).then(result => {
      this.statuses = result
    })
  }
}
